/* custom-slick.css */
.slick-prev, .slick-next {
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  
  .slick-prev {
    left: 40px;
  }
  
  .slick-next {
    right: 50px;
  }
  
  .slick-prev:before, .slick-next:before {
    font-size: 30px;
    color: white;
  }

  .arrow-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 20;
  }
  
  @media (max-width: 768px) {
    .arrow-wrapper {
      top: 5px;
      left: 5px;
    }
  }
  
  .slider-container {
    position: relative;
    max-width: 100vw;
    margin-bottom: 10px;
  }